<template>
  <div style="overflow: hidden !important;">
    <template v-if="colorsConfig.type == '17_deprecate'">
      <div class="tablet-widget-informador-container">
        <TableMundialWidgetInformador :season="1512" :custom="colorsConfig" :await-for-season="true" />
      </div>
    </template>
    <template v-if="colorsConfig.type == '16_deprecate'">
      <div class="tablet-widget-container">
        <TableMundial :season="1512" :custom="colorsConfig" :await-for-season="true" />
      </div>
    </template>
    <template v-if="colorsConfig.type === '15_deprecate'">
      <CalendarWidget :custom="colorsConfig" />
    </template>
    <template v-if="colorsConfig.type == '14_deprecate'">
      <Chronometer :custom="colorsConfig" />
    </template>
    <template
      v-if="
        colorsConfig.type == 1 ||
          colorsConfig.type == 8 ||
          colorsConfig.type == 9 ||
          colorsConfig.type == 10 ||
          colorsConfig.type == 12 ||
          colorsConfig.type == 13 ||
          colorsConfig.type == 18
      "
    >
      <div ref="liveGS">
        <NavBarFree :custom="colorsConfig" :widget-team="$route.params.team" />
      </div>
    </template>
    <template v-else>
      <NavBarFree
        v-if="
          (!isElInfomadorCalendar || !isElInformadorCountdown || !isElInformadorTable) &&
            ![14, 15, 16, 17].includes(colorsConfig.type)
        "
        :custom="colorsConfig"
        :widget-team="$route.params.team"
        isStadiumVisible
      />
    </template>
    <template
      v-if="
        colorsConfig.type == 1 ||
          colorsConfig.type == 8 ||
          colorsConfig.type == 9 ||
          colorsConfig.type == 10 ||
          colorsConfig.type == 13 ||
          colorsConfig.type == 12
      "
    >
      <template v-if="colorsConfig.type == 8">
        <!-- <MenuGolStats
          @scrollItem="scrollToElement"
          :sectionScroll="sectionScroll"
          :datosGS="true"
          :videosGS="false"
          :mejoresGS="false"
          :apostadorGS="true"
          :equiposGS="false"
          :previasGS="false"
          :tablaGS="false"
          :liveGS="true"
          layoutType="bets"
        /> -->
      </template>
      <template v-else>
        <template v-if="colorsConfig.type == 12">
          <!-- <MenuGolStats
            @scrollItem="scrollToElement"
            :sectionScroll="sectionScroll"
            :datosGS="true"
            :videosGS="false"
            :mejoresGS="true"
            :apostadorGS="true"
            :equiposGS="false"
            :previasGS="true"
            :tablaGS="true"
            :liveGS="true"
            :linkApostador="true"
            layoutType="main"
          /> -->
        </template>
        <template v-else>
          <template v-if="colorsConfig.type == 18">
            <!-- <MenuGolStats
              @scrollItem="scrollToElement"
              :sectionScroll="sectionScroll"
              :datosGS="true"
              :videosGS="false"
              :mejoresGS="true"
              :apostadorGS="true"
              :equiposGS="false"
              :previasGS="true"
              :tablaGS="true"
              :testimoniosGS="false"
              :liveGS="true"
              :linkApostador="true"
              layoutType="goltipster"
            /> -->
            <div v-if="colorsConfig.show_logo && tournamentSelectedM.showLogos" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
          </template>
          <template v-else>
            <!-- <MenuGolStats
              @scrollItem="scrollToElement"
              :sectionScroll="sectionScroll"
              :datosGS="true"
              :videosGS="false"
              :mejoresGS="true"
              :apostadorGS="true"
              :equiposGS="false"
              :previasGS="true"
              :tablaGS="true"
              :liveGS="true"
              layoutType="widget"
            /> -->
            <template v-if="colorsConfig.type != 13">
              <div v-if="colorsConfig.show_logo" class="bet-logo">
                <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
              </div>
            </template>
          </template>
        </template>
      </template>
    </template>
    <template v-if="colorsConfig.type == 13">
      <div class="landing-mundial">
        <div class="header-landing-mundial">
          <div class="header-landing-mundial__tournament-cont">
            <div class="header-landing-mundial__tournament-cont__label">
              Torneo
            </div>
            <div class="header-landing-mundial__tournament-cont__select">
              <BField position="is-centered" type="is-info" class="select-statistics">
                <BSelect
                  placeholder="Selecciona uno"
                  :value="tournamentSelectedM"
                  expanded
                  @input="setTournamentSelectedM"
                >
                  <option v-for="tournament in listTournaments" :key="tournament.id" :value="tournament">
                    {{ tournament.name }}
                  </option>
                </BSelect>
              </BField>
            </div>
          </div>
          <div class="header-landing-mundial__sep-cont">
            <div class="header-landing-mundial__sep-cont__line"></div>
          </div>
          <div class="header-landing-mundial__live-bottom-cont">
            <div
              class="header-landing-mundial__live-bottom-cont__buttom"
              :class="showLive ? 'color-active' : ''"
              @click="openLive"
            >
              LIVE
              <img src="/images/menu/icn_live.svg" />
            </div>
          </div>
          <div class="header-landing-mundial__sep-cont-movil">
            <div class="header-landing-mundial__sep-cont__line"></div>
          </div>
          <div class="header-landing-mundial__logo-sponsor-cont">
            <div class="header-landing-mundial__logo-sponsor-cont__sep"></div>
            <div v-if="tournamentSelectedM.showLogos" class="header-landing-mundial__logo-sponsor-cont__imgcont">
              <img src="/images/caliente.png" />
            </div>
          </div>
        </div>
        <div class="header-landing-mundial-movil">
          <div class="header-landing-mundial-movil__data">
            <div class="header-landing-mundial-movil__data__tournament-cont">
              <div class="header-landing-mundial-movil__data__tournament-cont__label">
                Torneo
              </div>
              <div class="header-landing-mundial-movil__data__tournament-cont__select">
                <BField position="is-centered" type="is-info" class="select-statistics">
                  <BSelect
                    placeholder="Selecciona uno"
                    :value="tournamentSelectedM"
                    expanded
                    @input="setTournamentSelectedM"
                  >
                    <option v-for="tournament in listTournaments" :key="tournament.id" :value="tournament">
                      {{ tournament.name }}
                    </option>
                  </BSelect>
                </BField>
              </div>
            </div>
            <div class="header-landing-mundial-movil__data__live-bottom-cont">
              <div
                class="header-landing-mundial-movil__data__live-bottom-cont__buttom"
                :class="showLive ? 'color-active' : ''"
                @click="openLive"
              >
                LIVE
                <img src="/images/menu/icn_live.svg" />
              </div>
            </div>
          </div>
          <div class="header-landing-mundial-movil__logo-sponsor-cont">
            <div class="header-landing-mundial-movil__logo-sponsor-cont__sep"></div>
            <div v-if="tournamentSelectedM.showLogos" class="header-landing-mundial-movil__logo-sponsor-cont__imgcont">
              <img src="/images/caliente.png" />
            </div>
          </div>
        </div>
        <div class="tools-mundial-cont">
          <div class="tools-mundial-cont__row-one">
            <div class="tools-mundial-cont__row-one__group">
              <div class="tools-mundial-cont__row-one__group__datags-cont">
                <GolstatsData20
                  :custom="colorsConfig"
                  :await-for-season="awaitForSeasonOrSeasons"
                  :season="seasonSelectedM"
                />
              </div>
              <div class="tools-mundial-cont__row-one__group__tops-cont">
                <TopMini
                  :custom="colorsConfig"
                  :await-for-season="awaitForSeasonOrSeasons"
                  :season="seasonSelectedM.id"
                  :tournament="tournamentSelectedM.id"
                  :is-cup="tournamentSelectedM.isCup"
                  is-widget
                />
              </div>
            </div>
            <div class="tools-mundial-cont__row-one__calendar" :class="tournamentSelectedM.isCup ? 'heightAux' : ''">
              <CalendarCup
                v-if="tournamentSelectedM.isCup"
                await-for-seasons
                :seasons="tournamentSelectedM.seasons"
                is-short-version
                is-widget
              />
              <Calendar
                v-else
                type="onlycomponent"
                await-for-seasons
                is-landing
                is-green-header-color
                is-widget
                :seasons="tournamentSelectedM.seasons"
              />
            </div>
          </div>
          <div class="tools-mundial-cont__row-two">
            <div class="tools-mundial-cont__row-two__table-general">
              <div class="table-normal-landing" v-if="!tournamentSelectedM.isCup">
                <div class="table-normal-landing__container-table">
                  <Table
                    :season="seasonSelectedM.id"
                    :custom="colorsConfig"
                    :await-for-season="awaitForSeasonOrSeasons"
                    :show-logos="tournamentSelectedM.showLogos"
                  />
                </div>
              </div>

              <TableMundial
                v-else
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
                :seasonget="seasonSelectedM.id"
                :show-logos="tournamentSelectedM.showLogos"
              />
            </div>
            <div class="tools-mundial-cont__row-two__bets-tool">
              <GolTipsterV2 v-if="tournamentSelectedM.isCup" id="Herramienta-apostadores" :are-live-games="showLive" />
            </div>
          </div>
        </div>
        <Lives :show="showModal" @close="closeModal" />
      </div>
    </template>
    <template v-if="colorsConfig.type == 18">
      <div id="betTool" ref="apostadorGS">
        <div class="gol-tipster">
          <div class="gol-tipster__body">
            <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
          </div>
        </div>
      </div>
      <template v-if="areType13ComponentsVisible">
        <div v-if="colorsConfig.show_logo" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
        <div class="landing-tops is-centered" ref="datosGS">
          <div class="landing-tops__container">
            <GolstatsData
              :custom="colorsConfig"
              @season-selected="seasonSelected"
              @tournament-selected="tournamentSelected"
              @seasons-selected="onSeasonsSelected"
            />
          </div>
        </div>
        <div ref="mejoresGS">
          <Tops
            id="Tops"
            v-observe-visibility="visibilityChanged"
            :season="seasonUpdate"
            :custom="colorsConfig"
            :await-for-season="awaitForSeasonOrSeasons"
          />
        </div>
        <div class="landing-tops is-centered" ref="previasGS">
          <div class="landing-tops__container">
            <div id="Calendario" class="landing-tops__section-1-desktop-2" v-observe-visibility="visibilityChanged">
              <div>
                <Calendar
                  type="only"
                  :custom="colorsConfig"
                  :seasons="seasonsSelectedForCalendar"
                  :await-for-seasons="awaitForSeasonOrSeasons"
                  is-full
                  @tournament-selected="onTournamentSelected"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="landing-tops__section-table-only-pc is-centered">
          <div id="Tabla-general" class="landing-tops__section-table-only-pc__container-table">
            <div ref="tablaGS">
              <div ref="tablaGS2">
                <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="colorsConfig.show_logo" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
      </template>
      <br />
    </template>
    <template v-else>
      <div class="landing-tops is-centered">
        <div class="landing-tops__container">
          <template v-if="colorsConfig.tournaments">
            <Selectors3Pv
              @season-selected="seasonSelected"
              @tournament-selected="tournamentSelected"
              @seasons-selected="onSeasonsSelected"
              :custom="colorsConfig.headerBackground"
            />
          </template>
          <template v-if="colorsConfig.type == 11">
            <div ref="apostadorGS">
              <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
            </div>
          </template>
          <template v-if="colorsConfig.type == 10">
            <div ref="datosGS">
              <GolstatsData
                :custom="colorsConfig"
                @season-selected="seasonSelected"
                @tournament-selected="tournamentSelected"
                @seasons-selected="onSeasonsSelected"
              />
            </div>

            <div class="landing-tops__section-1">
              <div id="Calendario" class="landing-tops__section-1__calendar" v-observe-visibility="visibilityChanged">
                <div ref="previasGS">
                  <Calendar
                    type="component"
                    :seasons="seasonsSelectedForCalendar"
                    :await-for-seasons="awaitForSeasonOrSeasons"
                    :custom="colorsConfig"
                    is-full
                    @tournament-selected="onTournamentSelected"
                  />
                </div>
              </div>

              <div id="Tabla-general" v-observe-visibility="visibilityChanged" class="landing-tops__section-1__table">
                <div ref="tablaGS">
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
            </div>
            <div ref="apostadorGS">
              <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
            </div>
            <div class="landing-tops__section-table-only is-centered">
              <div
                id="Tabla-general"
                v-observe-visibility="visibilityChanged"
                class="landing-tops__section-table-only__container-table"
              >
                <div ref="tablaGS2">
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
            </div>
            <div ref="mejoresGS">
              <Tops
                id="Tops"
                v-observe-visibility="visibilityChanged"
                :season="seasonUpdate"
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
              />
            </div>
          </template>
          <template v-if="colorsConfig.type == 2">
            <template v-if="tournamentUpdate == 1071">
              <div id="Calendario" class="landing-tops__section-1-desktop-2" v-observe-visibility="visibilityChanged">
                <Calendar
                  type="only"
                  :custom="colorsConfig"
                  :seasons="seasonsSelectedForCalendar"
                  :await-for-seasons="awaitForSeasonOrSeasons"
                  is-full
                  @tournament-selected="onTournamentSelected"
                />
              </div>
            </template>
            <template v-else>
              <div v-if="colorsConfig.show_logo" class="bet-logo">
                <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
              </div>
              <div class="landing-tops__section-table-only is-centered margin-none">
                <div
                  id="Tabla-general"
                  v-observe-visibility="visibilityChanged"
                  class="landing-tops__section-table-only__container-table"
                >
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
              <div class="landing-tops__section-1-type4">
                <div
                  id="Tabla-general"
                  v-observe-visibility="visibilityChanged"
                  class="landing-tops__section-1-type4__table"
                >
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
                <div
                  id="Calendario"
                  class="landing-tops__section-1-type4__calendar"
                  v-observe-visibility="visibilityChanged"
                >
                  <Calendar
                    type="component"
                    :seasons="seasonsSelectedForCalendar"
                    :await-for-seasons="awaitForSeasonOrSeasons"
                    :custom="colorsConfig"
                    is-full
                    @tournament-selected="onTournamentSelected"
                  />
                </div>
              </div>
            </template>
            <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <Tops
              id="Tops"
              v-observe-visibility="visibilityChanged"
              :season="seasonUpdate"
              :custom="colorsConfig"
              :await-for-season="awaitForSeasonOrSeasons"
            />
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
          </template>
          <template v-if="colorsConfig.type == 3">
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <template v-if="tournamentUpdate == 1071">
              <div id="Calendario" class="landing-tops__section-1-desktop-2" v-observe-visibility="visibilityChanged">
                <Calendar
                  type="only"
                  :custom="colorsConfig"
                  :seasons="seasonsSelectedForCalendar"
                  :await-for-seasons="awaitForSeasonOrSeasons"
                  is-full
                  @tournament-selected="onTournamentSelected"
                />
              </div>
            </template>
            <template v-else>
              <div class="landing-tops__section-1">
                <div class="landing-tops__section-1__calendar">
                  <Calendar
                    type="component"
                    :custom="colorsConfig"
                    :seasons="seasonsSelectedForCalendar"
                    :await-for-seasons="awaitForSeasonOrSeasons"
                    is-full
                    @tournament-selected="onTournamentSelected"
                  />
                </div>
                <div id="Tabla-general" v-observe-visibility="visibilityChanged" class="landing-tops__section-1__table">
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
              <div class="landing-tops__section-table-only is-centered">
                <div
                  id="Tabla-general"
                  v-observe-visibility="visibilityChanged"
                  class="landing-tops__section-table-only__container-table"
                >
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
            </template>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <Tops
              id="Tops"
              v-observe-visibility="visibilityChanged"
              :season="seasonUpdate"
              :custom="colorsConfig"
              :await-for-season="awaitForSeasonOrSeasons"
            />
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <br />
          </template>
          <template v-if="colorsConfig.type == 4">
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <template v-if="tournamentUpdate == 1071">
              <div id="Calendario" class="landing-tops__section-1-desktop-2" v-observe-visibility="visibilityChanged">
                <Calendar
                  type="only"
                  :custom="colorsConfig"
                  :seasons="seasonsSelectedForCalendar"
                  :await-for-seasons="awaitForSeasonOrSeasons"
                  is-full
                  @tournament-selected="onTournamentSelected"
                />
              </div>
            </template>
            <template v-else>
              <div class="landing-tops__section-table-only is-centered margin-none">
                <div
                  id="Tabla-general"
                  v-observe-visibility="visibilityChanged"
                  class="landing-tops__section-table-only__container-table"
                >
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
              <div class="landing-tops__section-1-type4">
                <div
                  id="Tabla-general"
                  v-observe-visibility="visibilityChanged"
                  class="landing-tops__section-1-type4__table"
                >
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
                <div
                  id="Calendario"
                  class="landing-tops__section-1-type4__calendar"
                  v-observe-visibility="visibilityChanged"
                >
                  <Calendar
                    type="component"
                    :custom="colorsConfig"
                    :seasons="seasonsSelectedForCalendar"
                    :await-for-seasons="awaitForSeasonOrSeasons"
                    is-full
                    @tournament-selected="onTournamentSelected"
                  />
                </div>
              </div>
            </template>

            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <Tops
              id="Tops"
              v-observe-visibility="visibilityChanged"
              :season="seasonUpdate"
              :custom="colorsConfig"
              :await-for-season="awaitForSeasonOrSeasons"
            />
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <br />
          </template>
          <template v-if="colorsConfig.type == 5">
            <div id="Calendario" class="landing-tops__section-1-desktop" v-observe-visibility="visibilityChanged">
              <Calendar
                type="onlycomponent"
                :custom="colorsConfig"
                :seasons="seasonsSelectedForCalendar"
                :await-for-seasons="awaitForSeasonOrSeasons"
                is-full
                @tournament-selected="onTournamentSelected"
              />
            </div>
          </template>
          <template v-if="colorsConfig.type == 6">
            <template v-if="tournamentUpdate != 1071">
              <div class="landing-tops__section-table-only-desktop is-centered">
                <div
                  id="Tabla-general"
                  v-observe-visibility="visibilityChanged"
                  class="landing-tops__section-table-only-desktop__container-table"
                >
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
            </template>
          </template>
          <template v-if="colorsConfig.type == 7">
            <Tops
              id="Tops"
              v-observe-visibility="visibilityChanged"
              :season="seasonUpdate"
              :custom="colorsConfig"
              :await-for-season="awaitForSeasonOrSeasons"
            />
          </template>
          <template v-if="colorsConfig.type == 8">
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <div ref="datosGS">
              <GolstatsData
                :custom="colorsConfig"
                @season-selected="seasonSelected"
                @tournament-selected="tournamentSelected"
                @seasons-selected="onSeasonsSelected"
              />
            </div>
            <div class="landing-tops__section-only-bets">
              <div ref="apostadorGS">
                <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
              </div>
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <br />
          </template>
          <template v-if="colorsConfig.type == 9">
            <div ref="datosGS">
              <GolstatsData
                :custom="colorsConfig"
                @season-selected="seasonSelected"
                @tournament-selected="tournamentSelected"
                @seasons-selected="onSeasonsSelected"
              />
            </div>
            <div class="landing-tops__section-only-bets">
              <div ref="apostadorGS">
                <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
              </div>
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>

            <div class="landing-tops__section-1">
              <div id="Calendario" class="landing-tops__section-1__calendar" v-observe-visibility="visibilityChanged">
                <div ref="previasGS">
                  <Calendar
                    type="component"
                    :seasons="seasonsSelectedForCalendar"
                    :await-for-seasons="awaitForSeasonOrSeasons"
                    :custom="colorsConfig"
                    is-full
                    @tournament-selected="onTournamentSelected"
                  />
                </div>
              </div>
              <div id="Tabla-general" v-observe-visibility="visibilityChanged" class="landing-tops__section-1__table">
                <div ref="tablaGS">
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
            </div>

            <div class="landing-tops__section-table-only is-centered">
              <div
                id="Tabla-general"
                v-observe-visibility="visibilityChanged"
                class="landing-tops__section-table-only__container-table"
              >
                <div ref="tablaGS2">
                  <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                </div>
              </div>
            </div>
            <div ref="mejoresGS">
              <Tops
                id="Tops"
                v-observe-visibility="visibilityChanged"
                :season="seasonUpdate"
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
              />
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <br />
          </template>

          <template v-if="colorsConfig.type == 12">
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <div ref="datosGS">
              <GolstatsData
                :custom="colorsConfig"
                @season-selected="seasonSelected"
                @tournament-selected="tournamentSelected"
                @seasons-selected="onSeasonsSelected"
              />
            </div>
            <div id="Calendario" class="landing-tops__section-1-desktop-2" v-observe-visibility="visibilityChanged">
              <div ref="previasGS">
                <Calendar
                  type="only"
                  :custom="colorsConfig"
                  :seasons="seasonsSelectedForCalendar"
                  :await-for-seasons="awaitForSeasonOrSeasons"
                  is-full
                  @tournament-selected="onTournamentSelected"
                />
              </div>
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <br />
            <div ref="mejoresGS">
              <Tops
                id="Tops"
                v-observe-visibility="visibilityChanged"
                :season="seasonUpdate"
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
              />
            </div>
            <div class="landing-tops__section-table-only-pc is-centered">
              <div id="Tabla-general" class="landing-tops__section-table-only-pc__container-table">
                <div ref="tablaGS">
                  <div ref="tablaGS2">
                    <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <br />
          </template>
          <template v-if="colorsConfig.type == 1">
            <div ref="datosGS">
              <GolstatsData
                :custom="colorsConfig"
                @season-selected="seasonSelected"
                @tournament-selected="tournamentSelected"
                @seasons-selected="onSeasonsSelected"
              />
            </div>
            <div class="landing-tops__section-only-bets">
              <div ref="apostadorGS">
                <GolTipsterV2 id="Herramienta-apostadores" v-observe-visibility="visibilityChanged" />
              </div>
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
            <div ref="previasGS">
              <div id="Calendario" class="landing-tops__section-1-desktop" v-observe-visibility="visibilityChanged">
                <Calendar
                  type="onlycomponent"
                  :custom="colorsConfig"
                  :seasons="seasonsSelectedForCalendar"
                  :await-for-seasons="awaitForSeasonOrSeasons"
                  is-full
                  @tournament-selected="onTournamentSelected"
                />
              </div>
            </div>
            <div ref="mejoresGS">
              <Tops
                id="Tops"
                v-observe-visibility="visibilityChanged"
                :season="seasonUpdate"
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
              />
            </div>
            <div class="landing-tops__section-table-only-pc is-centered">
              <div id="Tabla-general" class="landing-tops__section-table-only-pc__container-table">
                <div ref="tablaGS">
                  <div ref="tablaGS2">
                    <Table :season="seasonUpdate" :custom="colorsConfig" :await-for-season="awaitForSeasonOrSeasons" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="colorsConfig.show_logo" class="bet-logo">
              <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
            </div>
          </template>
        </div>
      </div>
    </template>
    <template
      v-if="colorsConfig.type == 1 || colorsConfig.type == 11 || colorsConfig.type == 13 || colorsConfig.type == 8"
    >
      <template v-if="colorsConfig.type == 1">
        <FooterGolTipster />
      </template>
      <template v-else>
        <template v-if="colorsConfig.type == 11">
          <FooterGolTipster :type="11" />
        </template>
        <template v-else>
          <template v-if="colorsConfig.type == 13">
            <FooterGolTipster :type="13" />
          </template>
          <template v-else>
            <template v-if="colorsConfig.type == 8">
              <FooterGolTipster :type="8" />
            </template>
            <template v-else>
              <FooterGolTipster />
            </template>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { widgetSeccionVista } from '@/utils/analytics';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { BField } from 'buefy/dist/esm/field';
import { BSelect } from 'buefy/dist/esm/select';
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

export default {
  name: 'Landing',
  components: {
    BField,
    BSelect,
    CalendarCup: () => import('@/components/Sections/Calendar/CalendarCup'),
    GolstatsData: () => import('@/views/GolstatsData'),
    GolstatsData20: () => import('@/views/GolstatsDataGS2'),
    TopMini: () => import('@/views/TopMini'),
    GolTipsterV2: () => import('@/components/Sections/GolTipster-v2/Main'),
    Tops: () => import('@/views/Tops'),
    Lives: () => import('@/views/Lives'),
    Table: () => import('@/components/Sections/Table/TableComponent'),
    TableMundial: () => import('@/components/Sections/Table/TableComponentMundial'),
    Chronometer: () => import('@/components/Sections/Chronometer/Chronometer'),
    Calendar: () => import('@/components/Sections/Calendar/CalendarComponentLanding'),
    NavBarFree: () => import('@/components/NavBars/NavBarFree'),
    Selectors3Pv: () => import('@/components/Sections/Selectors3Pv'),
    FooterGolTipster: () => import('@/components/Sections/Main/FooterGolTipster'),
    TableMundialWidgetInformador: () => import('@/components/Sections/Table/TableMundialWidgetInformador'),
    BetLogo: () => import('@/components/Elements/BetLogo'),
    CalendarWidget: () => import('@/components/Sections/Calendar-Widget/CalendarWidget'),
  },
  data() {
    return {
      urlLives: 'https://qobetc4ze3.execute-api.us-east-2.amazonaws.com/prod/games/live',
      // url2: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      url2: 'https://kefloixzy1.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      urlData: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      retries: 3,
      showLive: false,
      showModal: false,
      category: { id: 1, name: 'Goles', bluer: false },
      listTournaments: [],
      listSeasonsByTournament: [],
      listSeasons: {},
      groupedSeasons: null,
      tournamentSelectedM: {
        seasonsForCalendar: [],
      },
      seasonSelectedM: {},

      season: null,
      tournament: null,
      sectionScroll: 'datosGS',
      tips: '/tips',
      url: 'https://2dp6unm5we.execute-api.us-west-2.amazonaws.com/prod/custom_views/',
      colorsConfig: {},
      seasonsSelectedForCalendar: [],
      seasonsSelectedForRightBet: [],
      areType13ComponentsVisible: false,
    };
  },
  destroyed() {},
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapState('general', ['sectionRef']),
    ...mapGetters('loginGeneral', ['isLoggedIn', 'getIsGolstats']),
    isElInfomadorCalendar() {
      return this.$route.params.team === 'ElInformadorCalendar';
    },
    isElInformadorCountdown() {
      return this.$route.params.team === 'ElInformadorCountdown';
    },
    isElInformadorTable() {
      return this.$route.params.team === 'ElInformadorTable';
    },
    seasonUpdate() {
      return this.season;
    },
    tournamentUpdate() {
      return this.tournament;
    },
    awaitForSeasonOrSeasons() {
      if (this.colorsConfig.tournaments === undefined) {
        return true;
      }
      return this.colorsConfig.tournaments;
    },
  },
  watch: {
    colorsConfig: {
      immediate: true,
      handler: function(val) {
        if (val.type === 13) {
          val.logo = 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png';
          val.color = '#cbee6e';
          val.background = '#132839';
          val.colorheader = '#132839';
          val.backgroundheader = '#CCE8B5';
          val.headerBackground = '#132839';
          val.headerColor = '#CBEE6E';
          val.headerBorderColor = '#CBEE6E';
          val.header2Background = '#132839';
          val.header2Color = '#FFFFFF';
          val.subHeaderBackground = '#cbee6b';
          val.subHeaderColor = '#353535';
          val.headerBetToolBackground = '#242424';
          val.subheaderBetToolBackground = '#151515';
          this.sectionScroll = 'apostadorGS';
          if (!this.areType13ComponentsVisible) {
            setTimeout(() => {
              this.areType13ComponentsVisible = true;
            }, 5000);
          }
        } else {
          this.sectionScroll = 'datosGS';
        }
      },
    },
    sectionRef(newValue) {
      if (this.colorsConfig.type === 13 && !this.areType13ComponentsVisible) {
        this.areType13ComponentsVisible = true;
        setTimeout(() => {
          this.scrollToElement(newValue);
        }, 2000);
      } else {
        this.scrollToElement(newValue);
      }
    },
  },
  async created() {
    try {
      await this.getLivesData();
      await this.fillListTournaments();
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    if (this.$route.query.d && this.$route.query.d == 1) {
      this.SET_AUTH_COMPONENT('SelectPlan');
    }
    if (this.$route.params.team) {
      localStorage.setItem('widget-team', this.$route.params.team);
    }
    if (this.isElInfomadorCalendar || this.isElInformadorCountdown || this.isElInformadorTable) {
      this.colorsConfig = {
        background: '#242424',
        backgroundheader: '#CCE8B5',
        bet_house: 'caliente',
        categories: 'all',
        color: '#CBEE6B',
        colorheader: '#132839',
        header2Background: '#132839',
        header2Color: '#FFFFFF',
        headerBackground: '#132839',
        headerBetToolBackground: '#242424',
        headerBorderColor: '#CBEE6E',
        headerColor: '#CBEE6E',
        logo: 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        main_logo: 'https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg',
        partner_id: 54,
        partner_link: '',
        partner_logo: 'https://az755631.vo.msecnd.net/golstats-bets/partner-akron.jpg',
        secondary_logo: 'https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg',
        show_logo: true,
        subHeaderBackground: '#CBEE6B',
        subheaderBetToolBackground: '#151515',
        subHeaderColor: '#353535',
        team_id: 0,
        tournaments: false,
        type: this.isElInfomadorCalendar ? 15 : this.isElInformadorCountdown ? 14 : 17,
        url_base:
          'http://online.caliente.mx/promoRedirect?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54623980&lp=0&var1=web',
      };
    } else {
      this.getCustom(this.$route.params.team);
    }
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('registerGeneral', ['set3pvid']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setTournamentSelected', 'setSectionAnalytics']),
    ...mapMutations('profileGeneral', ['setColorsConfig']),
    handleScrolling(event) {
      if (this.$refs['datosGS']) {
        let sizeDatosGS = this.$refs['datosGS'].offsetTop + this.$refs['datosGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['datosGS'].offsetTop && scrollCalc <= sizeDatosGS) {
          this.sectionScroll = 'datosGS';
        }
      }
      if (this.$refs['testimoniosGS']) {
        let sizePreviasGS = this.$refs['testimoniosGS'].offsetTop + this.$refs['testimoniosGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['testimoniosGS'].offsetTop && scrollCalc <= sizePreviasGS) {
          this.sectionScroll = 'testimoniosGS';
        }
      }
      if (this.$refs['previasGS']) {
        let sizePreviasGS = this.$refs['previasGS'].offsetTop + this.$refs['previasGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['previasGS'].offsetTop && scrollCalc <= sizePreviasGS) {
          this.sectionScroll = 'previasGS';
        }
      }
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      if (vw < 1080) {
        if (this.colorsConfig.type != 10) {
          if (this.$refs['tablaGS2']) {
            let sizeTablaGS = this.$refs['tablaGS2'].offsetTop + this.$refs['tablaGS2'].offsetHeight;
            let scrollCalc = event.target.scrollTop + 136;
            if (scrollCalc >= this.$refs['tablaGS2'].offsetTop && scrollCalc <= sizeTablaGS) {
              this.sectionScroll = 'tablaGS';
            }
          }
        } else {
          if (this.$refs['tablaGS']) {
            let sizeTablaGS = this.$refs['tablaGS'].offsetTop + this.$refs['tablaGS'].offsetHeight;
            let scrollCalc = event.target.scrollTop + 136;
            if (scrollCalc >= this.$refs['tablaGS'].offsetTop && scrollCalc <= sizeTablaGS) {
              this.sectionScroll = 'tablaGS';
            }
          }
        }
      } else {
        if (this.$refs['tablaGS']) {
          let sizeTablaGS = this.$refs['tablaGS'].offsetTop + this.$refs['tablaGS'].offsetHeight;
          let scrollCalc = event.target.scrollTop + 136;
          if (scrollCalc >= this.$refs['tablaGS'].offsetTop && scrollCalc <= sizeTablaGS) {
            this.sectionScroll = 'tablaGS';
          }
        }
      }
      if (this.$refs['apostadorGS']) {
        let sizeApostadorGS = this.$refs['apostadorGS'].offsetTop + this.$refs['apostadorGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['apostadorGS'].offsetTop && scrollCalc <= sizeApostadorGS) {
          this.sectionScroll = 'apostadorGS';
        }
      }
      if (this.$refs['mejoresGS']) {
        let sizeMejoresGS = this.$refs['mejoresGS'].offsetTop + this.$refs['mejoresGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['mejoresGS'].offsetTop && scrollCalc <= sizeMejoresGS) {
          this.sectionScroll = 'mejoresGS';
        }
      }
    },
    scrollToElement(val) {
      if (this.colorsConfig === 13 && !this.areType13ComponentsVisible) {
        setTimeout(() => {
          this.areType13ComponentsVisible = true;
        }, 2000);
      } else if (val === 'apostadorGS' && this.colorsConfig.type === 12) {
        let routeData = this.$router.resolve({ name: 'goltipster' });
        window.open(routeData.href, '_blank');
      } else {
        let el = this.$refs[val];
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (vw < 1080 && val == 'tablaGS') {
          el = this.$refs['tablaGS2'];
        }
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        const sectionName = entry.target.id;
        widgetSeccionVista({
          value: sectionName,
          team: this.$route.params.team,
          is_premium: this.user.premiumAccount,
          golstats: this.getIsGolstats,
        });
      }
    },
    onPremiumDisabledClicked(message) {
      this.setSectionAnalytics(message);
      this.SET_PREMIUM_MESSAGE(true);
    },
    async getCustom(team) {
      try {
        let query = '';
        if (this.$route.params.team === 'bets') {
          query = `?ref=${document.referrer || 'home'}&t=1&ut=${this.user.premiumAccount ? '2' : '1'}`;
        }
        const data = await axios
          .get(this.url + team + query)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getCustom(team);
            } else {
              this.colorsConfig = {
                logo: 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png',
                color: '#FFFFFF',
                headerBackground: '#242424',
                headerColor: '#FFFFFF',
                headerBorderColor: '#6EB913',
                header2Background: '#3b3b3b',
                header2Color: '#FFFFFF',
                subHeaderBackground: '#cce8b5',
                subHeaderColor: '#353535',
                type: 1,
                team_id: 0,
                tournaments: false,
                categories: 'all',
                bet_house: '',
                main_logo: '',
                secondary_logo: '',
                show_logo: false,
                url_base: '',
              };
            }
            return e;
          });
        if (data.data) {
          this.colorsConfig = data.data;
          localStorage.setItem('colorsConfig', JSON.stringify(data.data));
          this.setColorsConfig({ ...data.data });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    seasonSelected($valor) {
      this.season = $valor;
    },
    tournamentSelected(tournament) {
      this.tournament = tournament.id;
      this.setTournamentSelected(tournament);
    },
    /**
     * @author Alejandro Martinez
     * @param {Array<{id: number, type: number}>} seasonsSelected
     */
    onSeasonsSelected(seasonsSelected) {
      this.seasonsSelectedForCalendar = seasonsSelected;
      this.seasonsSelectedForRightBet = seasonsSelected;
    },
    onTournamentSelected(tournament) {
      this.setTournamentSelected(tournament);
    },
    async onFiltersFetched() {
      try {
        if (this.$route.params.team !== 'bets') {
          return;
        }
        const logsUrl = 'https://95ow8l4hkh.execute-api.us-east-2.amazonaws.com/prod/visitlogs';
        const body = {
          name: this.$route.params.team,
          ref: document.referrer || 'home',
          type: 2,
          user_type: this.user.premiumAccount ? 2 : 1,
        };
        await axios.post(logsUrl, body);
      } catch (e) {
        throw new Error(e);
      }
    },

    //mundial
    async setTournamentSelectedM(val) {
      this.tournamentSelectedM = val;
      this.listSeasonsByTournament = [];
      this.listSeasonsByTournament.push(this.listSeasons[this.tournamentSelectedM.id]);
      this.seasonSelectedM = this.listSeasons[this.tournamentSelectedM.id];
    },
    async fillListTournaments() {
      try {
        const { data: tournaments } = await axios
          .get(this.url2)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.fillListTournaments();
            }
            return e;
          });
        this.setTournamentsData(tournaments);
      } catch (e) {
        throw new Error(e);
      }
    },
    setTournamentsData(tournaments) {
      this.createGroupedSeasons(tournaments);
      if (tournaments) {
        for (const tournament of tournaments) {
          const tournamentAux = {
            name: tournament.tournament.name,
            id: tournament._id,
            logo: tournament.tournament.logo,
            isCup: !!tournament.tournament.tournament_type,
            showLogos: !!tournament.tournament.show_logos,
            seasons: tournament.seasons.reduce((seasons, season, index) => {
              if (index === 0) {
                seasons.push({ id: season.id, type: season.type, name: season.name });
                if (season.related_seasons && season.related_seasons.length) {
                  seasons.push(
                    ...season.related_seasons.map(relatedSeason => ({
                      id: relatedSeason.id,
                      type: relatedSeason.type,
                    })),
                  );
                }
              }
              return seasons;
            }, []),
          };
          this.listTournaments.push(tournamentAux);
          for (let indexSeason = 0; indexSeason < tournament.seasons.length; indexSeason++) {
            if (indexSeason == 0) {
              this.listSeasons[tournament._id] = {
                name: tournament.seasons[indexSeason].name,
                id: tournament.seasons[indexSeason].id,
                logo: tournament.seasons[indexSeason].logo,
              };
            }
          }
          if (tournament._id === 1) {
            this.setTournamentSelectedM(tournamentAux);
          }
        }
      }
      this.listSeasonsByTournament.push(this.listSeasons[this.tournamentSelectedM.id]);
      this.seasonSelected = this.listSeasons[this.tournamentSelectedM.id];
    },
    createGroupedSeasons(tournamentsAndSeasons) {
      this.groupedSeasons = tournamentsAndSeasons.reduce((groupedSeasons, tournamentAndSeasons) => {
        const regularSeason = tournamentAndSeasons.seasons[0];
        groupedSeasons[regularSeason.id] = [
          { id: regularSeason.id, type: regularSeason.type, name: regularSeason.name },
        ];
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          groupedSeasons[regularSeason.id].push(
            ...regularSeason.related_seasons.map(relatedSeason => ({
              id: relatedSeason.id,
              type: relatedSeason.type,
            })),
          );
        }
        return groupedSeasons;
      }, {});
    },
    async getLivesData() {
      try {
        const { data: games } = await axios
          .get(this.urlLives)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getLivesData();
            }
            return e;
          });
        if (games) {
          this.showLive = true;
        } else {
          this.showLive = false;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    openLive() {
      if (this.showLive) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScrolling, true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrolling);
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
.tablet-widget-informador-container {
  display: inline-block;
  width: 300px;
  height: 600px;
  // background: rgb(230, 230, 230);
  margin: 0 auto;
}
.tablet-widget-container {
  display: inline-block;
  width: 1248px;
  height: 474px;
  // background: rgb(230, 230, 230);
  margin: 0 auto;
  @media screen and (max-width: 1248px) {
    width: 100%;
  }
}
* {
  scroll-margin-top: 128px;
  @media screen and (max-width: 634px) {
    scroll-margin-top: 118px;
  }
}
.margin-none {
  margin-top: 25px !important;
}
.landing-tops {
  position: relative;
  display: flex;
  justify-content: center;

  margin-bottom: 0px !important;
  &__section-only-bets {
    margin-top: 30px;
  }
  &__landing-load {
    width: 100%;
    height: 600px;
    position: relative;
    &__container {
      width: 280px;
      height: 130px;
      display: inline-block;
      position: fixed;
      top: 45%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      &__title {
        font-family: 'Circular-Std-Medium';
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #a7a7a7;
        text-align: center;
        font-size: 25px;
      }
      &__subtittle {
        font-family: 'Circular-Std-Medium';
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #a7a7a7;
        &__bold {
          font-family: 'Circular-Std';
        }
      }
    }
  }
  &__container {
    width: 100%;
    position: relative;
    max-width: 1268px;
    height: auto;
    background: white;
  }

  &__section-1 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    &__calendar {
      margin-right: 5px;
      @media screen and (max-width: 1080px) {
        margin-right: 0px;
      }
    }
    &__table {
      margin-left: 5px;
      max-height: 700px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 100%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-1-type4 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    &__calendar {
      margin-left: 5px;
      @media screen and (max-width: 1080px) {
        margin-right: 0px;
      }
    }
    &__table {
      margin-right: 5px;
      max-height: 700px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 100%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-1-desktop {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 80px;
    margin-top: 40px;
  }
  &__section-1-desktop-2 {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 80px;
    margin-top: 40px;
    @media screen and (max-width: 1080px) {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-calendar-only {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    display: none;
    &__container-calendar {
      width: 100%;
      max-width: 1268px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
    display: none;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only-pc {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only-desktop {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
  }
}
.gol-tipster {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  &__body {
    width: 100%;
    max-width: 79.25rem;
    height: auto;
    background: white;

    &__bet-tool {
      width: 100%;

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 2.25em 0;

          & > img {
            height: 2.75em;
            width: 2.75em;
            margin: 0.5em;
          }

          & > div {
            padding-top: 0.2em;
            font-size: 2.9em;
            font-family: Circular-Std-Bold, sans-serif;
          }

          @media screen and (max-width: 380px) {
            margin: 1.8em 0;
            font-size: 0.8rem !important;
          }

          @media screen and (max-width: 350px) {
            font-size: 0.7rem !important;
          }
        }

        &__buttons {
          display: flex;

          & > div {
            margin: 0 0.5rem;

            @media screen and (max-width: 744px) {
              flex-grow: 1;
            }

            @media screen and (max-width: 480px) {
              margin: 0 0.2rem;
            }
          }
        }

        &__section {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 1rem;
        }
      }
    }
  }
}
.bet-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1em 0 0.6em;
}

//landinf mundiall

.heightAux {
  @media screen and (max-width: 500px) {
    height: 500px !important;
  }
}
.table-normal-landing {
  display: flex;
  justify-content: center;
  &__container-table {
    width: 100%;
    max-width: 618px;
    height: auto;
  }
}
.color-active {
  color: black !important;
  background-color: #cbee6d !important;
  img {
    filter: brightness(0%) !important;
  }
}
.color-active:hover {
  cursor: pointer;
  opacity: 0.8;
}
.tools-mundial-cont {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 20px;
  &__row-one {
    width: 100%;
    height: 480px;
    display: inline-flex;
    @media screen and (max-width: 1328px) {
      display: inline-block;
      margin-bottom: 22px;
      height: auto;
    }
    &__group {
      width: 638px;
      display: inline-flex;
      height: auto;
      @media screen and (max-width: 1328px) {
        width: 100%;
        height: 480px;
      }
      @media screen and (max-width: 628px) {
        width: 100%;
        height: auto;
      }
      @media screen and (max-width: 428px) {
        display: inline-block;
      }
      &__datags-cont {
        width: 302.5px;
        height: auto;
        display: inline-block;
        border: 1px solid #c0c0c0;
        @media screen and (max-width: 1328px) {
          width: 49%;
          height: 480px;
        }
        @media screen and (max-width: 628px) {
          width: 49.8%;
          height: 412px;
        }
        @media screen and (max-width: 428px) {
          width: 100%;
          height: 151px;
        }
      }
      &__tops-cont {
        width: 303.5px;
        height: auto;
        // background: #e8e8e8;
        margin-left: 32px;
        display: inline-block;
        @media screen and (max-width: 1328px) {
          width: 49%;
          margin-left: 25px;
          height: 480px;
        }
        @media screen and (max-width: 628px) {
          margin-left: 2px;
          width: 49.8%;
          height: 410px;
        }
        @media screen and (max-width: 428px) {
          width: 100%;
          margin-left: 0%;
          margin-top: 25px;
        }
      }
    }
    &__calendar {
      width: 600px;
      margin-left: 32px;
      @media screen and (max-width: 1328px) {
        width: 100%;
        height: auto; //pasara a ser auto
        margin-left: 0;
        margin-top: 25px;
      }
      @media screen and (max-width: 828px) {
        width: 100%;
        margin-left: 0;
        margin-top: 25px;
      }
      @media screen and (max-width: 628px) {
        width: 100%;
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
  &__row-two {
    width: 100%;
    height: auto;
    display: inline-block;
    margin-top: 72px;
    @media screen and (max-width: 768px) {
      margin-top: 54px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 12px;
    }
    &__table-general {
      width: 100%;
      display: inline-block;
      height: auto; //pasara a ser auto
    }
    &__bets-tool {
      width: 100%;
      display: inline-block;
      height: auto; //pasara a ser auto
      margin-top: 72px;
      @media screen and (max-width: 768px) {
        margin-top: 54px;
      }
      @media screen and (max-width: 500px) {
        margin-top: 12px;
      }
    }
  }
}
.landing-mundial {
  width: 100%;
  max-width: 1248px;
  display: inline-block;
  margin: 0 auto;
  height: auto;
  justify-content: center;
  margin-bottom: 0 !important;
}
.header-landing-mundial-movil {
  display: none;
  width: 100%;
  height: 168px;
  justify-content: center;
  align-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    display: inline-block;
  }
  &__data {
    width: 100%;
    height: 72px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
    &__tournament-cont {
      width: 65%;
      height: 41px;
      display: inline-block;
      justify-content: left;
      align-content: center;
      align-items: center;

      &__label {
        font-family: 'Roboto-Regular';
        font-size: 15px;
        height: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.13px;
        text-align: left;
        color: #3c444b;
        margin-right: 10px;
        width: 20%;
        margin-left: 10px;
      }
      &__select {
        width: 95%;
        height: 41px;
        margin-left: 10px;
        @media screen and (max-width: 400px) {
          width: 89%;
        }
      }
    }

    &__live-bottom-cont {
      width: 35%;
      height: 41px;
      display: inline-flex;
      justify-content: right;
      align-content: center;
      align-items: center;
      &__buttom {
        width: 115px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 8px;
        background-color: #e8e8e8;
        font-family: 'Avenir-Pro-Bold';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #c9ccce;
        margin-right: 10px;
        padding-left: 10px;
        margin-top: 47px;

        img {
          margin-left: 3px;
          filter: grayscale(1);
        }
      }
    }
  }
  &__logo-sponsor-cont {
    width: 100%;
    height: 85.7px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-left: 0%;
    align-items: center;
    position: relative;
    &__sep {
      width: 100%;
      height: 2px;
      border-bottom: 1px dotted #aeb0b2;
      position: absolute;
    }
    &__imgcont {
      width: 212px;
      height: 85.7px;
      background: white;
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      img {
        width: 68%;
      }
    }
  }
}
.header-landing-mundial {
  width: 100%;
  display: inline-flex;
  height: 110px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    display: none;
  }
  &__tournament-cont {
    width: 27.36%;
    height: 41px;
    display: inline-flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    @media screen and (max-width: 1120px) {
      width: 30%;
    }
    @media screen and (max-width: 960px) {
      width: 31%;
    }
    @media screen and (max-width: 860px) {
      width: 44.5%;
    }
    @media screen and (max-width: 600px) {
      width: 45%;
    }

    &__label {
      font-family: 'Roboto-Regular';
      font-size: 18px;
      height: 41px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 35px;
      letter-spacing: -0.13px;
      text-align: left;
      color: #3c444b;
      margin-right: 10px;
      width: 20%;
      margin-left: 10px;
      @media screen and (max-width: 560px) {
        margin-left: 3px;
        margin-right: 5px;
        width: 25%;
      }
    }
    &__select {
      width: 80%;
      height: 41px;
    }
  }
  &__sep-cont {
    width: 6.24%;
    height: 41px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    &__line {
      width: 2px;
      height: 16px;
      border-right: 1px solid #aeb0b2;
    }
    @media screen and (max-width: 860px) {
      width: 7.24%;
    }
    @media screen and (max-width: 650px) {
      width: 5.24%;
    }
  }
  &__sep-cont-movil {
    width: 6.24%;
    height: 41px;
    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    &__line {
      width: 2px;
      height: 16px;
      border-right: 1px solid #aeb0b2;
    }
    @media screen and (max-width: 860px) {
      display: inline-flex;
      width: 7.24%;
    }
    @media screen and (max-width: 650px) {
      width: 6.24%;
    }
  }
  &__live-bottom-cont {
    width: 9.21%;
    height: 41px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    @media screen and (max-width: 1120px) {
      width: 11.21%;
    }
    @media screen and (max-width: 960px) {
      width: 13.21%;
    }
    @media screen and (max-width: 860px) {
      width: 16.21%;
    }
    @media screen and (max-width: 600px) {
      width: 18.21%;
    }

    &__buttom {
      width: 115px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 8px;
      background-color: #e8e8e8;
      font-family: 'Avenir-Pro-Bold';
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #c9ccce;

      padding-left: 10px;
      img {
        margin-left: 3px;
        filter: grayscale(1);
      }
    }
  }
  &__logo-sponsor-cont {
    width: 55.85%;
    height: 85.7px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-left: 2.4%;
    align-items: center;
    position: relative;
    @media screen and (max-width: 1120px) {
      width: 50%;
    }
    @media screen and (max-width: 960px) {
      width: 47%;
    }
    @media screen and (max-width: 860px) {
      margin-left: 0%;
      width: 212px;
      justify-content: right;
    }
    @media screen and (max-width: 650px) {
      width: 180px;
    }
    @media screen and (max-width: 600px) {
      width: 150px;
    }
    &__sep {
      width: 100%;
      height: 2px;
      border-bottom: 1px dotted #aeb0b2;
      position: absolute;
      @media screen and (max-width: 860px) {
        display: none;
      }
    }
    &__imgcont {
      width: 212px;
      height: 85.7px;
      background: white;
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      @media screen and (max-width: 650px) {
        width: 180px;
      }
      @media screen and (max-width: 600px) {
        width: 150px;
      }
      img {
        width: 78%;
        @media screen and (max-width: 860px) {
          width: 53%;
        }
        @media screen and (max-width: 600px) {
          width: 65%;
        }
      }
    }
  }
}
</style>
